export const ANALYTICS = {
  USER_TRAITS: 'userTraits',
  PAGE_REACHED_HOME: 'BLIP_PACKS_EVENTS_GO_TESTING_EVENT',
  BlipPacksTrackingSegment: 'BLIP_PACKS_EVENTS_TRACK_DATA',
  LogoutEvent: 'BLIP_PACKS_EVENTS_LOGOUT',
  OPEN_LITE_PLATAFORM: 'blippacks-management-general-opened',
  PG_OPEN_MODULE: 'blippacks-management-general-module',
  PG_OPEN_GREETINGS: 'blippacks-loggedarea-pa-bot-greetings',
  PG_OPEN_AUTOMATIC_RESPONSES: 'blippacks-loggedarea-pa-bot-menu',
  PG_OPEN_ATTENDANTS: 'blippacks-loggedarea-pa-bot-attendants',
  PG_OPEN_HUMAN_ATTENDANCE_QUEUE: 'blippacks-loggedarea-pa-human-attendance-queue',
  PG_OPEN_HUMAN_ATTENDANCE_OPENING_HOURS: 'blippacks-loggedarea-pa-human-attendance-opening-hours',
  INTRO_TEST_BLIP_CHAT: 'blippacks-management-general-channels-test-blip-chat',
  INTRO_SHOW_PLANS: 'blippacks-management-general-channels-see-plans',
  WPP_MODAL_CLOSE: 'blippacks-management-general-channels-meet-blip-go-close',
  NUMBER_PREPARATION_OPEN: 'blippacks-management-general-channels-wpp-postsignature',
  NUMBER_PREPARATION_REPORT_PROBLEM: 'blippacks-management-general-channels-wpp-reportproblem',
  NUMBER_PREPARATION_DELETE_ACCOUNT_TUTORIAL: 'blippacks-management-general-channels-wpp-howdeleteaccount',
  NUMBER_PREPARATION_DELETED_PHONE: 'blippacks-management-general-channels-wpp-deleted-account',
  NUMBER_PREPARATION_REGISTER_NEW_PHONE: 'blippacks-management-general-channels-wpp-newphone',
  PAYWALL_TEST_BLIP_CHAT: 'blippacks-management-general-channels-paywall-test-bot',
  PAYWALL_UPGRADE_PLAN: 'blippacks-management-general-channels-paywall-upgrade',
  PAYWALL_OPEN: 'blippacks-management-general-channels-paywall-screen',
  PAYWALL_LEARN_MORE: 'blippacks-management-general-channels-paywall-learn-more',
  ACTIVATION_FORM_MERCHANT_INFO: 'blippacks-management-general-channels-merchant-infos',
  META_OPEN: 'blippacks-management-general-channels-wpp-registerMeta',
  META_REGISTER_NOW: 'blippacks-management-general-channels-wpp-registernow',
  META_OPEN_EMBEDDED: 'blippacks-management-general-channels-wpp-registerMeta-opened',
  META_REPORT_PROBLEM: 'blippacks-management-general-channels-wpp-reportproblem',
  META_EMBEDDED_REDIRECT: 'blippacks-management-general-channels-wpp-registerMeta-redirected',
  META_CONNECT_API_CLICK: 'blippacks-management-general-channels-whatsapp-connect-to-api',
  META_EMBEDDED_FINISHED: 'blippacks-management-general-channels-wpp-registerMeta-completed',
  META_LEARN_MORE: 'blippacks-management-general-channels-wpp-moreinfoMeta',
  META_RETRY_OPEN_EMBEDDED: 'blippacks-management-general-channels-wpp-registerMeta-opened',
  CONTRACT_INFO_HIRE_PLAN: 'blippacks-management-general-channels-whatsapp-hired-plan',
  PROCESS_UPGRADE_HIRE_PLAN_CANCEL: 'blippacks-management-general-channels-whatsapp-cancel-plan-hiring',
  PROCESS_UPGRADE_CONNECT_API: 'blippacks-management-general-contract-connect-whatsapp',
  CONTRACT_COMPANY_REPRESENTATIVE_INFO: 'blippacks-management-general-contract-company-representative-info-filled',
  CONTRACT_PLAN_RETURN: 'blippacks-management-general-contract-company-return',
  CONTRACT_COMPANY_COMPLETE: 'blippacks-management-general-contract-company-contract',
  CLICK_CHATBOT_TEST_BUTTON: 'blippacks-loggedarea-settings-test-bot',
  CLICK_SAVE_BUTTON: 'blippacks-loggedarea-settings-pa-saved',
  CLICK_DELETE_ITEM_ICON: 'blippacks-loggedarea-settings-pa-delete',
  PG_OPEN_CHANNELS: 'blippacks-managment-channels-opened',
  CLICK_TEST_CHANNEL_BUTTON: 'blippacks-management-general-channels-tested',
  CLICK_CONFIG_CHANNEL_BUTTON: 'blippacks-management-general-channels-configured',
  CLICK_CONNECTED_CHANNEL_BUTTON: 'blippacks-management-general-channels-activated',
  CLICK_NEXT_COMPANY_CONTRACT_PAGE: 'blippacks-management-general-contract-company-info-filled',
  DOCUSIGN_CLICKWRAP_AGREE: 'blippacks-management-general-contract-agree',
  DOCUSIGN_CLICKWRAP_DECLINE: 'blippacks-management-general-contract-decline',
  PAGE_HOME_ACCESS: 'blippacks-management-general-choose-channel',
  CLICK_BUTTON_CHOOSE_CHANNEL: 'blippacks-management-general-choose-channel-btn',
  HEADER_PRO_PLAN_UPGRADE: 'blippacks-management-general-home-sign-pro',
  STEPS_CHEKC_LIST: 'blippacks-management-general-home-steps-to-custumize',
  YOUTUBE_VIDEO_STATE_CHANGE: 'blippacks-management-general-home-video-interactions',
  INDEPENDENT_CONNECTION: 'blippacks-management-general-contract-self-connect',
  ASSISTED_CONNECTION: 'blippacks-management-general-contract-connect-with-help',
  CHANNELS_BLIP_CHAT_CONFIGURED: 'blippacks-management-general-channels-blip-chat-configured',
  INSTAGRAM_ACTIVATION_RETURN_BUTTON: 'blippacks-managment-instagram-connect-go-back',
  INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION_PAGE: 'blippacks-managment-instagram-connection',
  INSTAGRAM_ACTIVATION_CONNECT_FACEBOOK_BUTTON: 'blippacks-managment-instagram-connect-facebook',
  INSTAGRAM_ACTIVATION_POP_UP_CLOSED_BY_USER: 'blippacks-managment-instagram-connection-closed',
  INSTAGRAM_ACTIVATION_ERROR: 'blippacks-managment-instagram-page-error',
  INSTAGRAM_ACTIVATION_ERROR_BACK: 'blippacks-managment-instagram-page-error-back',
  INSTAGRAM_ACTIVATION_NO_ACCOUNTS_FOUND_CHANGE_ACCOUNT_LINK: 'blippacks-managment-instagram-page-change-account',
  INSTAGRAM_ACTIVATION_NO_ACCOUNTS_FOUND_CREATE_PAGE_LINK: 'blippacks-managment-instagram-page-error-createFB',
  INSTAGRAM_ACTIVATION_NO_ACCOUNTS_FOUND_TRY_AGAIN_BUTTON:
    'blippacks-managment-instagram-page-error-createFB-try-again',
  INSTAGRAM_ACTIVATION_CHANGE_ACCOUNT_BUTTON: 'blippacks-channel-connection-instagram-changed-account',
  INSTAGRAM_ACTIVATION_CHOOSE_PAGE_PAGE: 'blippacks-channel-connection-instagram-viewpage-connect',
  INSTAGRAM_ACTIVATION_CHOOSE_PAGE_BUTTON: 'blippacks-channel-connection-instagram-click-connect',
  INSTAGRAM_ACTIVATION_CONFIRM_INSTAGRAM_PAGE: 'blippacks-channel-connection-instagram-confirmed-accounts',
  INSTAGRAM_ACTIVATION_CHOOSE_INSTAGRAM_BUTTON: 'blippacks-channel-connection-instagram-connection',
  INSTAGRAM_ACTIVATION_CONNECTION_SUCCESS: 'blippacks-channel-connection-instagram-connection-successful',
  INSTAGRAM_ACTIVATION_CONNECTION_ERROR: 'blippacks-channel-connection-instagram-connection-error',
  INSTAGRAM_ACTIVATION_SHOW_NO_PAGE_FOUND_MODAL: 'blippacks-channel-connection-instagram-cant-find-page',
  INSTAGRAM_ACTIVATION_CREATE_NEW_PAGE_MODAL: 'blippacks-channel-connection-instagram-create-new-page',
  INSTAGRAM_ACTIVATION_BIND_PAGES_PAGE: 'blippacks-channel-connection-instagram-linked-accounts',
  INSTAGRAM_ACTIVATION_BIND_PAGES_BUTTON: 'blippacks-channel-connection-instagram-how-link-accounts',
  INSTAGRAM_ACTIVATION_OPEN_FACEBOOK_PAGE_NEW_TAB: 'blippacks-channel-connection-instagram-acessed-page',
  INSTAGRAM_ACCOUNT_BIND_PAGES_FINISHED_BUTTON: 'blippacks-channel-connection-instagram-how-link-accounts-finished',
  BLIPCHAT_SIMULATOR_CLICK_HOME: 'blippacks-management-general-initiate-bot-simulator',
  BLIPCHAT_RESTART_SIMULATOR_CLICK_HOME: 'blippacks-management-general-restart-bot-simulator',
  SIMULATOR_SATISFACTION_SURVEY: 'blippacks-management-general-initiate-bot-simulator-search',
  LEARN_ABOUT_PRO_PLAN: 'blippacks-management-general-channels-meet-pro-plan',
  CLOSE_MODAL_PRO_PLAN: 'blippacks-management-general-channels-meet-pro-plan-close',
  META_POLICY_LINK_CLICK: 'blippacks-management-general-contract-company-info-link-meta-policies-clicked',
  ACTIVE_MESSAGES_SEND: 'blippacks-management-general-active-message-send',
  ACTIVE_MESSAGES_SELECTED_MODEL: 'blippacks-management-general-active-message-selected-model',
  ACTIVE_MESSAGES_MESSAGE_NEXT: 'blippacks-management-general-active-message-next',
  ACTIVE_MESSAGES_MESSAGE_BACK: 'blippacks-management-general-active-message-back',
  ACTIVE_MESSAGES_MESSAGE_UPLOAD_CTT: 'blippacks-management-general-active-message-upload-ctt',
  ACTIVE_MESSAGES_MESSAGE_DOWNLOAD_XLSX: 'blippacks-management-general-active-message-download-xlsx',
  ACTIVE_MESSAGES_MESSAGE_CANCEL: 'blippacks-management-general-active-message-cancel',
  ACTIVE_MESSAGES_MESSAGE_CANCEL_MODAL: 'blippacks-management-general-active-message-cancel-modal',
  ACTIVE_MESSAGES_MESSAGE_RESPONSE_TARGETING: 'blippacks-management-general-active-message-response-targeting',
  ACTIVE_MESSAGES_MESSAGE_EDIT: 'blippacks-management-general-active-message-edit',
  ACTIVE_MESSAGES_MESSAGE_SEND: 'blippacks-management-general-active-message-send',
  ACTIVE_MESSAGES_CREATE: 'blippacks-management-general-active-message-create',
  TOGGLE_FAQ: 'blippacks-loggedarea-pa-bot-menu-disable-faq',
  VIEW_PLAN_DETAILS_HEADER_BUTTTON: 'blippacks-management-general-access-plan',
  PLAN_DETAILS_SIDEBAR_BUTTON: 'blippacks-management-general-billing',
  BLIP_GO_LOGOUT: 'blippacks-management-general-logout',
  MANAGEMENT_HELP_CENTER: 'blippacks-management-general-help-center',
  MANAGEMENT_REQUEST_SUPPORT: 'blippacks-management-general-request-support',
  EXTENSIONS_ADDON_CARD: 'blippacks-management-general-extensions-and-applications-card-extension',
  EXTENSIONS_ADDON_BUTTON: 'blippacks-management-general-extensions-and-applications-card-extension-btn',
  EXTENSIONS_BLIPCHAT_CARD: 'blippacks-management-general-extensions-and-applications-card-app',
  SUBSCRIBE_PRO_PLAN_MODAL: 'blippacks-management-general-meet-pro-plan',
  AUTO_REPLY_ADD_OPTION: 'blippacks-loggedarea-settings-automatic-responses-add',
  ATTENDANCE_ADD_TEAM: 'blippacks-loggedarea-settings-team-add',
  ATTENDANCE_DELETE_TEAM: 'blippacks-loggedarea-settings-team-delete',
  ATTENDANCE_24H: 'blippacks-loggedarea-settings-human-attendance-24h',
  ATTENDANCE_HOLIDAYS_ACTIVATE: 'blippacks-loggedarea-human-settings-holidays',
  HELP_SUBMENU: 'blippacks-management-general-help-submenu',
  CONTRACT_AGREEMENT: 'blippacks-management-general-contract-company-aware-contract',
  OPEN_CONTACTS: 'blippacks-management-general-contacts',
  CONTACT_CLICK: 'blippacks-management-general-contacts-card-contact',
  INVITE_MEMBER: 'blippacks-loggedarea-settings-pa-invited-attendants',
  SUBSCRIBE_BASIC_PLAN_MODAL: 'blippacks-management-general-channels-meet-basic-plan',
  CLOSE_MODAL_BASIC_PLAN: 'blippacks-management-general-channels-meet-basic-plan-close',
  OFFER_BANNER_TEAM_ATTENDANTS: 'blippacks-loggedarea-pa-human-attendance-queue-meet-pro-plan',
  OFFER_BANNER_ATTENDANTS: 'blippacks-loggedarea-pa-bot-attendants-meet-pro-plan',
  TOGGLE_BOT_PERMISSION: 'blippacks-loggedarea-permissions',
  BLIPGO_LINK_OPENED: 'blippacks-blipgo-linkopened',
  SETUP_INSTALLATION_START: 'blippacks-setup-installation-start',
  SETUP_INSTALLATION_OBJECTIVES: 'blippacks-setup-fill-objectives',
  SETUP_INSTALLATION_CUSTOMIZE_SERVICE: 'blippacks-setup-customize-service',
  SO_INSTALLATION_STATUS: 'blippacks-selfonboarding-installation-status',
  SO_INSTALLATION_TIMEOUT: 'blippacks-selfonboarding-installation-timeout',
  SO_INSTALLATION_RESTART: 'blippacks-selfonboarding-restart-installation',
  SO_INSTALLATION_OPEN_PAGE_RESTART: 'blippacks-selfonboarding-installation-failure',
  OFFER_BANNER_BLACK_FRIDAY: 'blippacks-management-general-black-friday-offer',
  OFFER_BANNER_BLACK_FRIDAY_CLOSE: 'blippacks-management-general-close-black-friday-offer',
};
