import { BdsBanner, BdsBannerLink, BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ProPlanModal } from 'components/ProPlanModal/ProPlanModal';
import ExternalUrls from 'constants/ExternalUrls';
import { META_CONNECTION_ROUTE, PAYWALL_ROUTE, PLAN_DETAILS_ROUTE, SIGNATURE_ROUTE } from 'constants/Routes';
import { Analytics } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { useAuth } from 'oidc-react';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { LogOut } from 'services/Auth';

type HeaderProps = {
  title?: string;
};

const Header: React.FC<HeaderProps> = ({ title }: HeaderProps) => {
  const auth = useAuth();
  const { pathname } = useLocation();
  const { activeModule, loggedUser } = useSelector(useProject);
  const { planType } = useSelector(useProject);

  const [currentUrlPath, setCurrentUrlPath] = useState('');
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  const modalRef = useRef<null | HTMLBdsModalElement>(null);

  useEffect(() => {
    setCurrentUrlPath(pathname);
    const bannerClosed = localStorage.getItem('bannerClosed');
    const currentDate = new Date();
    const endDate = new Date('2023-12-01');
    if (bannerClosed === 'true' || currentDate < endDate || planType === PlanTypeEnum.Pro) {
      setShowBanner(false);
    }
  }, [pathname]);

  const openPaywall = () => {
    const screenName = currentUrlPath.split('/').pop();
    navigate(PAYWALL_ROUTE);
    Analytics.Track(Analytics.events.HEADER_PRO_PLAN_UPGRADE, { screenName });
  };

  const openModalPlanPro = () => {
    modalRef?.current?.toggle?.();
  };

  const isOnActivationPath = () => {
    return (
      currentUrlPath.includes(META_CONNECTION_ROUTE) ||
      currentUrlPath.includes(SIGNATURE_ROUTE) ||
      currentUrlPath.includes(PAYWALL_ROUTE)
    );
  };

  const handleBannerClose = () => {
    Analytics.Track(Analytics.events.OFFER_BANNER_BLACK_FRIDAY_CLOSE);
    setShowBanner(false);
    localStorage.setItem('bannerClosed', 'true');
  };

  const handleBannerLink = () => {
    Analytics.Track(Analytics.events.OFFER_BANNER_BLACK_FRIDAY);
  };
  return (
    <BdsGrid direction="column">
      {showBanner && (
        <BdsBanner buttonClose="true" variant="info" onBdsBannerClose={handleBannerClose}>
          Black Friday Blip Go: Assine o Blip Go PRO até 30/11 e ganhe 200 conversas extras!
          <BdsBannerLink link={ExternalUrls.blipGOBlackFriday} onBdsBannerLink={handleBannerLink}>
            Resgatar Oferta
          </BdsBannerLink>
        </BdsBanner>
      )}
      <BdsGrid style={{ background: '#fff' }} justifyContent="space-between">
        <BdsGrid alignItems="center" padding="2">
          <BdsTypo variant="fs-24" bold="extra-bold" margin={false}>
            {title ?? activeModule.title}
          </BdsTypo>
        </BdsGrid>
        <BdsGrid alignItems="center" gap="2" margin="r-3">
          <BdsGrid margin="r-3">
            <CTAButton
              isOnActivationPath={isOnActivationPath}
              navigate={navigate}
              openPaywall={openPaywall}
              planType={planType}
              modalRef={modalRef}
              openModalPlanPro={openModalPlanPro}
            />
          </BdsGrid>

          <BdsTypo>Olá, {loggedUser}</BdsTypo>
          <BdsTypo
            style={{ color: '#1E6BF1', cursor: 'pointer' }}
            data-testid="click-logout"
            onClick={() => LogOut(auth)}
            bold="semi-bold"
          >
            (Sair)
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
};

function CTAButton({
  planType,
  isOnActivationPath,
  openPaywall,
  navigate,
  openModalPlanPro,
  modalRef,
}: {
  planType: PlanTypeEnum | undefined;
  isOnActivationPath: () => boolean;
  openPaywall: () => void;
  navigate: (path: string) => void;
  openModalPlanPro: () => void;
  modalRef: RefObject<HTMLBdsModalElement>;
}) {
  if (planType == PlanTypeEnum.Pro) {
    return (
      <BdsButton
        icon="bill"
        variant="tertiary"
        onBdsClick={() => {
          Analytics.Track(Analytics.events.VIEW_PLAN_DETAILS_HEADER_BUTTTON);
          navigate(PLAN_DETAILS_ROUTE);
        }}
        data-testid="view-plan-header-button"
      >
        Acessar meu plano
      </BdsButton>
    );
  }
  if (!isOnActivationPath()) {
    if (planType == PlanTypeEnum.Free) {
      return (
        <BdsGrid>
          <BdsGrid margin="r-3" style={{ width: '205px' }}>
            <BdsTypo variant="fs-14" style={{ textAlign: 'right' }}>
              Assine o <b>plano Pro</b> e atenda seus clientes no WhatsApp!
            </BdsTypo>
          </BdsGrid>
          <BdsButton icon="verified" variant="primary" onClick={openPaywall} data-testid="header-upgrade-plan-btn">
            Atenda pelo WhatsApp
          </BdsButton>
        </BdsGrid>
      );
    } else if (planType == PlanTypeEnum.Basic) {
      return (
        <>
          <BdsButton
            icon="builder-publish-bot"
            variant="primary"
            onClick={openModalPlanPro}
            data-testid="header-upgrade-plan-pro-btn"
          >
            Assine o plano Pro
          </BdsButton>
          <ProPlanModal modalRef={modalRef} activeMessageOrigin={true} />
        </>
      );
    }
  }
  return <></>;
}

export default Header;
