import { BdsGrid, BdsIllustration, BdsProgressBar, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { BlipGoId } from 'configurations/Environment';
import { INSTALLATION, INSTALLATION_PROGRESS } from 'constants/Installation';
import { getResourcesOnboarding } from 'constants/ResourcesNames';
import { ONBOARDING_STEPPER } from 'constants/SelfOnboard';
import { Analytics, Logger } from 'infra/adapters';
import {
  BusinessInformartionOnboarding,
  CompanyObjectivesOnboarding,
  PersonalizeServiceOnboarding,
} from 'objects/types/OnboardingOptions';
import { ContainerProgressBar } from 'pages/Onboarding/styles';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'redux/slices/AccountSlice/accountSlice';
import { addAttendantInQueue, createDirectionLead, createOnboardingOptions } from 'services/BlipGoApiService';
import { LocalStorageService } from 'services/LocalStorageService';
import {
  deleteInstallation,
  getInstallationByInstallationId,
  installPack,
  installStatusProcess,
  setInstallationResources,
} from 'services/PackService';
import { createToastError, createToastInfo, createToastSuccess } from 'services/Toats';
import { formatLogMessage } from 'utils/LogMessage';

interface IFinishingInstallation {
  businessInformation: BusinessInformartionOnboarding;
  companyObjectives: CompanyObjectivesOnboarding;
  personalizeService: PersonalizeServiceOnboarding;
  setActiveStep: (step: number) => void;
}

type ProgressBar = {
  start: boolean;
  percent: number;
};

export const FinishingInstallation = ({
  businessInformation,
  companyObjectives,
  personalizeService,
  setActiveStep,
}: IFinishingInstallation) => {
  const className = 'FinishingInstallation';
  const timeoutInMilliseconds = 1000 * 60 * 6;
  const intervalInMilliseconds = 5 * 1000;
  let totalTime = 0;

  const { profile, token } = useSelector(useAccount);

  const progressBarIntervalRef = useRef<NodeJS.Timer>();
  const installedRef = useRef<boolean>(false);

  const [progressBar, setProgressBar] = useState<ProgressBar>({ start: true, percent: 0 });
  const navigate = useNavigate();

  const installationFailureToast = () =>
    createToastError('Falha na preparação', 'Por favor, tente novamente daqui alguns minutos.');

  useEffect(() => {
    const progress = localStorage.getItem(INSTALLATION_PROGRESS);
    startInstallation(progress ? parseInt(progress) : 0);
  }, []);

  const completedInstallationPolling = async (installationId: string) => {
    try {
      const data = await installStatusProcess(Number(BlipGoId), installationId, token);
      const installationStatusAlreadyExists = data.length > 0;

      if (!installationStatusAlreadyExists) {
        return false;
      }
      if (data[0].status == 'Completed') {
        setProgressBar(prevState => ({ ...prevState, percent: 100 }));
        createToastSuccess('Preparação do Pack concluída com sucesso.');
        return true;
      }
      if (data[0].status == 'Canceled') {
        Analytics.Track(Analytics.events.SO_INSTALLATION_STATUS, { status: 'Falha', error: data[0].errorCode });
        await removeInstallation();
        installationFailureToast();
        return false;
      }

      if (!progressBarIntervalRef.current) {
        let currentProgress = 1;
        let delayDivider = 1;

        progressBarIntervalRef.current = setInterval(() => {
          currentProgress += (Math.random() * 1.7) / delayDivider;

          if (currentProgress >= 100) {
            setProgressBar(prevState => ({ ...prevState, percent: 100 }));
          } else {
            setProgressBar(prevState => ({ ...prevState, percent: currentProgress }));

            if (installedRef.current) {
              delayDivider = 0.25;
            } else {
              delayDivider += currentProgress > 60 ? 0.02 : 0.01;
            }
          }
        }, 500);
      }
    } catch (error: any) {
      createToastError('Falha ao obter o status da preparação', 'Por favor, tente novamente daqui alguns minutos.');
      Analytics.Track(Analytics.events.SO_INSTALLATION_STATUS, { status: 'Falha', error });
    }
    return false;
  };

  const startCompletedInstallationPolling = async (installationId: string) => {
    return new Promise<void>((resolve, reject) => {
      const pollingInterval = setInterval(async () => {
        try {
          const data = await completedInstallationPolling(installationId);

          if (data) {
            clearInterval(pollingInterval);
            resolve();
          }

          totalTime += intervalInMilliseconds;

          if (totalTime >= timeoutInMilliseconds) {
            clearInterval(pollingInterval);
            handleTimeout();
            reject(new Error('Timeout: Tempo de espera excedido'));
          }
        } catch (error) {
          clearInterval(pollingInterval);
          reject(error);
        }
      }, intervalInMilliseconds);
    });
  };

  const handleTimeout = async () => {
    Analytics.Track(Analytics.events.SO_INSTALLATION_TIMEOUT);
    await removeInstallation();
    createToastInfo(
      'Preparação pausada',
      'A preparação excedeu o tempo esperado. Tente continuar daqui alguns minutos.',
    );
  };

  const removeInstallation = async () => {
    const installation = LocalStorageService.Get(INSTALLATION);
    if (profile.email && installation) {
      await deleteInstallation(
        Number(BlipGoId),
        profile.email,
        installation.installationId,
        'Falha na instalação',
        token,
      );
    }
  };

  const startInstallation = async (startFromStep = 0) => {
    const methodName = 'startInstallation';
    let statusInstallationTrack = 'Falha';
    try {
      if (profile.email) {
        if (startFromStep <= 0) {
          const data = await installPack(businessInformation.companyName, profile.email, token);
          const installationDetails = await getInstallationByInstallationId(BlipGoId, data.installationId, token);
          LocalStorageService.Add(INSTALLATION, {
            installationId: data.installationId,
            email: profile.email,
            tenantId: installationDetails.tenant.id,
            routerShortName: installationDetails.routerShortName,
            deskShortName: installationDetails.deskShortName,
            businessInformation,
            companyObjectives,
            personalizeService,
          });
          await startCompletedInstallationPolling(data.installationId);
          startFromStep++;
          localStorage.setItem(INSTALLATION_PROGRESS, startFromStep.toString());
        }

        const installation = LocalStorageService.Get(INSTALLATION);

        if (installation) {
          if (startFromStep <= 1) {
            await saveResources(installation?.routerShortName);
            startFromStep++;
            localStorage.setItem(INSTALLATION_PROGRESS, startFromStep.toString());
          }
          if (startFromStep <= 2) {
            await addDirectionLead(installation?.installationId);
            startFromStep++;
            localStorage.setItem(INSTALLATION_PROGRESS, startFromStep.toString());
          }
          if (startFromStep <= 3) {
            await linkAttendantInQueue(installation?.tenantId, installation?.deskShortName);
            startFromStep++;
            localStorage.setItem(INSTALLATION_PROGRESS, startFromStep.toString());
          }
          if (startFromStep <= 4) {
            await addOnboardingOptions(installation?.routerShortName);
            startFromStep++;
            localStorage.setItem(INSTALLATION_PROGRESS, startFromStep.toString());
          }
        }

        if (startFromStep == 5) {
          statusInstallationTrack = 'Sucesso';
          // If all steps are successfully completed, proceed to the next step
          localStorage.removeItem(INSTALLATION_PROGRESS);
          localStorage.removeItem(INSTALLATION);
          window.location.href = '/';
        }
      }
    } catch (error: any) {
      statusInstallationTrack = 'Falha';
      createToastError('Falha ao criar a instalação', error);
      Logger.Fatal(formatLogMessage(error, 'Failed create installation'), {
        methodName,
        className,
      });
      if (error && typeof error === 'string' && error.includes('Já existe uma instalação com o nome do cliente')) {
        setActiveStep(ONBOARDING_STEPPER.companyData);
      } else {
        navigate('/installation-error');
      }
    } finally {
      Analytics.Track(Analytics.events.SO_INSTALLATION_STATUS, { status: statusInstallationTrack });
    }
  };

  const getLoadingText = () => {
    const formattedPercent = progressBar.percent.toFixed(2).replace('.', ',');
    return `${formattedPercent}% concluído - ${getStatusByPercent()}`;
  };
  const getStatusByPercent = () => {
    if (progressBar.percent < 10) {
      return 'aguarde enquanto preparamos seu pack...';
    }
    if (progressBar.percent < 20) {
      return 'separando os materiais...';
    }
    if (progressBar.percent < 40) {
      return 'organizando as ferramentas...';
    }
    if (progressBar.percent < 60) {
      return 'preparando o ambiente...';
    }
    if (progressBar.percent < 80) {
      return 'só mais um pouco...';
    } else return 'deixando tudo pronto...';
  };

  const saveResources = async (routerShortName: string) => {
    const methodName = 'saveResources';
    try {
      await setInstallationResources(routerShortName, getResourcesOnboarding(businessInformation.companyName), token);
    } catch (error: any) {
      Logger.Fatal(formatLogMessage(error, 'Failed to save resources Pack'), {
        methodName,
        className,
      });
      throw new Error('Falha na criação de recursos do Bot');
    }
  };

  const addDirectionLead = async (installationId: number) => {
    const methodName = 'addDirectionLead';
    try {
      if (profile.email) {
        const source = LocalStorageService.Get('source');
        await createDirectionLead(
          installationId,
          businessInformation.companyName,
          profile.email,
          source?.si?.toUpperCase() ?? 'Other',
          businessInformation.companyPosition,
          businessInformation.companySite,
        );
      }
    } catch (error: any) {
      Logger.Fatal(formatLogMessage(error, 'Failed to create direction lead'), {
        methodName,
        className,
      });
      throw new Error('Falha na criação de usuário');
    }
  };

  const linkAttendantInQueue = async (tenantId: string, deskShortName: string) => {
    const methodName = 'linkAttendantInQueue';
    try {
      const QUEUES_DEFAULT = ['Default', 'Atendimento Geral'];
      if (profile.email) {
        await addAttendantInQueue(tenantId, deskShortName, [profile.email], QUEUES_DEFAULT);
      }
    } catch (error: any) {
      Logger.Fatal(formatLogMessage(error, 'Failed to link attendant in queue'), {
        methodName,
        className,
      });
      throw new Error('Falha ao vincular atendente com a fila de atendimento');
    }
  };

  const addOnboardingOptions = async (routerShortName: string) => {
    const methodName = 'addDirectionLead';
    try {
      await createOnboardingOptions({
        botId: routerShortName,
        attendants: personalizeService.attendants,
        companyName: businessInformation.companyName,
        companyPosition: businessInformation.companyPosition,
        companySite: businessInformation.companySite,
        objectives: companyObjectives.objectives,
        selectedFeatures: personalizeService.selectedFeatures,
        functions: personalizeService.selectedFeatures,
        textOtherObjective: companyObjectives.textOtherObjective,
        acceptTerms: businessInformation.acceptTerms,
      });
    } catch (error: any) {
      Logger.Fatal(formatLogMessage(error, 'Failed to create direction lead'), {
        methodName,
        className,
      });
      throw new Error('Falha ao salvar informações do formulário');
    }
  };

  return (
    <ContainerProgressBar>
      <BdsGrid
        style={{ alignItems: 'center', width: '37.5rem', marginTop: '2.5rem', marginLeft: '6.375rem' }}
        direction="column"
      >
        <BdsIllustration style={{ height: '12.5 rem', width: '12.5rem' }} name="time-1" type="default" />
        <BdsTypo style={{ marginTop: '1.5rem', textAlign: 'center' }} variant="fs-32" bold="bold" tag="h2">
          Quase lá! Estamos finalizado a configuração da sua conta Blip Go
        </BdsTypo>
        <BdsTypo variant="fs-16" bold="regular">
          Este processo pode levar alguns minutos.
        </BdsTypo>

        <BdsGrid margin="3" direction="column">
          <BdsProgressBar size="default" percent={progressBar.percent} style={{ width: '35rem' }} />
          <BdsTypo variant="fs-12" bold="semi-bold">
            {getLoadingText()}
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </ContainerProgressBar>
  );
};
