import './style.scss';
import {
  BdsButton,
  BdsGrid,
  BdsLoadingSpinner,
  BdsTable,
  BdsTableBody,
  BdsTableHeader,
  BdsTableRow,
  BdsTableTh,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useEffect, useState } from 'react';
import { getMessagesTemplates } from 'services/BlipGoApiService';
import { Template } from 'objects/types/MessageTemplate';
import { createToastError } from 'services/Toats';
import { TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE } from 'constants/ToastMessages';
import { TemplateListItem } from '../TemplateListItem/TemplateListItem';
import { formatLogMessage } from 'utils/LogMessage';
import { Analytics, Logger } from 'infra/adapters';
import { Alert } from 'components/Alert/Alert';
import { ApplicationDetail } from 'configurations/Environment';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';

export interface IActiveMessagesProViewSelectTemplateProps {
  handleTemplateClick: (template: Template) => void;
}

export const ActiveMessagesProViewSelectTemplate = ({
  handleTemplateClick,
}: IActiveMessagesProViewSelectTemplateProps) => {
  const className = 'ActiveMessagesProViewSelectTemplate';
  const { tenant, router } = useSelector(useInstallation);
  const { whatsAppActive } = useSelector(useProject);
  const [messageTemplates, setMessageTemplates] = useState<Template[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openRedirectConfirmation, setOpenRedirectConfirmation] = useState(false);

  const sendActiveMessagesUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/contents/messagetemplate`;

  useEffect(() => {
    if (whatsAppActive) {
      loadTemplates();
    }
  }, []);

  const loadTemplates = async () => {
    const methodName = 'loadTemplates';
    try {
      setIsLoading(true);
      const res = await getMessagesTemplates(tenant.id, router.shortName);
      setMessageTemplates(res);
    } catch (ex) {
      createToastError(TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE);
      Logger.Error(formatLogMessage(ex, `Failed to get template messages.`), {
        methodName,
        className,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectConfirm = () => {
    window.open(sendActiveMessagesUrl, '_blank');
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SEND);
    setOpenRedirectConfirmation(false);
  };

  return (
    <>
      {isLoading ? (
        <BdsGrid justifyContent="center" alignItems="center" height="100%">
          <BdsLoadingSpinner />
        </BdsGrid>
      ) : (
        <>
          <BdsGrid justifyContent="space-between" alignItems="center">
            <BdsTypo variant="fs-20" bold="bold" margin={false}>
              Comece selecionando um modelo da mensagem pré-aprovada
            </BdsTypo>
            <BdsButton
              className="button-create-template"
              variant="primary"
              onBdsClick={() => setOpenRedirectConfirmation(true)}
              data-testid="button-create-template"
            >
              Criar modelo
            </BdsButton>
          </BdsGrid>
          <BdsTable className="templates-table">
            <BdsTableHeader className="templates-table-header">
              <BdsTableRow>
                <BdsTableTh>Nome</BdsTableTh>
                <BdsTableTh>Mensagem</BdsTableTh>
                <BdsTableTh></BdsTableTh>
              </BdsTableRow>
            </BdsTableHeader>
            <BdsTableBody>
              {messageTemplates?.map((template, index) => {
                return (
                  <TemplateListItem
                    key={index}
                    id={template.templateId}
                    text={template.body?.text}
                    onTemplateClick={() => handleTemplateClick(template)}
                  />
                );
              })}
            </BdsTableBody>
          </BdsTable>
          <Alert
            open={openRedirectConfirmation}
            title={`Criar modelo ?`}
            description="Você será direcionado para a plataforma Blip para criar um modelo para sua mensagem."
            icon="info"
            variant="system"
            onCancel={() => setOpenRedirectConfirmation(false)}
            onConfirm={handleRedirectConfirm}
          />
        </>
      )}
    </>
  );
};
